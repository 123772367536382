import React from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function TopBar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="flex-row justify-between px-10 flex items-center py-4 sticky top-0 bg-[#161616]">
      <img src="/logo.png" alt="Smart lot pro" />
      <div className="flex-row flex items-center gap-10">
        <p>WELCOME, {user?.dealership || "USER"}</p>
        <button 
          className="py-2.5 px-10 rounded-md text-white"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default TopBar;
