import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiInstance } from "../api/api";
import { useAuth } from "../context/AuthContext";

function Login() {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await apiInstance.post("/auth/login", credentials);
      localStorage.setItem("token", response.data.data.token);
      login(response.data.data.user);
      navigate("/");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#161616]">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-8">
          <img src="/logo.png" alt="Smart lot pro" className="h-12" />
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-6">
            <label className="block text-gray-100 text-sm font-medium mb-2">
              Email
            </label>
            <input
              type="email"
              className="bg-gray-700 text-white w-full p-3 rounded-lg"
              value={credentials.email}
              onChange={(e) =>
                setCredentials({ ...credentials, email: e.target.value })
              }
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-100 text-sm font-medium mb-2">
              Password
            </label>
            <input
              type="password"
              className="bg-gray-700 text-white w-full p-3 rounded-lg"
              value={credentials.password}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-red-600 text-white p-3 rounded-lg font-medium"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login; 