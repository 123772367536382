import React, { useEffect, useState } from "react";
import { apiInstance } from "../api/api";
import { FaCar, FaUser, FaBuilding, FaMapMarkerAlt, FaParking } from "react-icons/fa";

function Home() {
  const [summary, setSummary] = useState({
    vehicles: 0,
    users: 0,
    companies: 0,
    locations: 0,
    lots: 0,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const [vehiclesRes, usersRes, companiesRes, locationsRes, lotsRes] = await Promise.all([
          apiInstance.get("/cars"),
          apiInstance.get("/users"),
          apiInstance.get("/companies"),
          apiInstance.get("/locations"),
          apiInstance.get("/lots"),
        ]);

        setSummary({
          vehicles: vehiclesRes.data.data.length,
          users: usersRes.data.data.length,
          companies: companiesRes.data.data.length,
          locations: locationsRes.data.data.length,
          lots: lotsRes.data.data.length,
        });
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold text-white mb-8">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex items-center">
          <FaCar className="text-white text-4xl mr-4" />
          <div>
            <h2 className="text-xl font-semibold text-white">Vehicles</h2>
            <p className="text-white">{summary.vehicles}</p>
          </div>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex items-center">
          <FaUser className="text-white text-4xl mr-4" />
          <div>
            <h2 className="text-xl font-semibold text-white">Users</h2>
            <p className="text-white">{summary.users}</p>
          </div>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex items-center">
          <FaBuilding className="text-white text-4xl mr-4" />
          <div>
            <h2 className="text-xl font-semibold text-white">Companies</h2>
            <p className="text-white">{summary.companies}</p>
          </div>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex items-center">
          <FaMapMarkerAlt className="text-white text-4xl mr-4" />
          <div>
            <h2 className="text-xl font-semibold text-white">Locations</h2>
            <p className="text-white">{summary.locations}</p>
          </div>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex items-center">
          <FaParking className="text-white text-4xl mr-4" />
          <div>
            <h2 className="text-xl font-semibold text-white">Parking Lots</h2>
            <p className="text-white">{summary.lots}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home; 