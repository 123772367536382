import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./router/BrowserRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <div>
        <ToastContainer position="top-right" autoClose={3000} theme="dark" />
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
}

export default App;
